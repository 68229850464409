var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      staticStyle: { "max-width": "900px", "border-radius": "6px" },
    },
    [
      _c("div", { staticClass: "form-row no-print justify-content-center" }, [
        _c(
          "div",
          { staticClass: "col-md-2" },
          [
            _c(
              "router-link",
              {
                staticClass: "btn-block btn btn-light mt-1 no-print",
                staticStyle: { display: "block", margin: "auto" },
                attrs: { to: "" + _vm.$previousRoute },
              },
              [_vm._v(" VOLTAR ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "col-md-2" }, [
          _c(
            "button",
            {
              staticClass: "btn-block btn btn-primary mt-1 no-print",
              staticStyle: { display: "block", margin: "auto" },
              attrs: { type: "button" },
              on: { click: _vm.printPage },
            },
            [_vm._v(" IMPRIMIR ")]
          ),
        ]),
      ]),
      _c("div", { ref: "printContainer", attrs: { id: "printContainer" } }),
      _c(
        "div",
        { ref: "imprimir", staticClass: "d-block mt-1" },
        [
          _c("b-card", { staticClass: "mb-1" }, [
            _c("h4", { staticClass: "text-center mb-0" }, [
              _c(
                "div",
                {
                  staticClass: "form-row",
                  staticStyle: {
                    "align-content": "center",
                    "font-size": "1.1rem",
                    "justify-content": "space-between",
                    "align-items": "center",
                  },
                },
                [
                  _c("div", { staticClass: "col-md-3 col-sm-3 text-left" }, [
                    _c("strong", [_vm._v("Nº " + _vm._s(_vm.item.id))]),
                  ]),
                  _c("div", { staticClass: "col-md-6 text-center col-sm-6" }, [
                    _c("strong", { staticClass: "title-page" }, [
                      _vm._v("PEDIDO DE COMPRA E LIBERAÇÃO DE PAGAMENTOS "),
                    ]),
                  ]),
                  _c("div", { staticClass: "col-md-3 col-sm-3" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm._f("datePT")(_vm.item.created_at, true))
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]),
          _c("b-card", { staticClass: "mb-1" }, [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                _c("h5", { staticClass: "mb-1" }, [
                  _c("strong", [_vm._v("FORNECEDOR")]),
                ]),
              ]),
              _c("div", { staticClass: "col-md-5 col-sm-5" }, [
                _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                  _vm._v(" " + _vm._s(_vm.item.supplier.title) + " "),
                ]),
                _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                  _vm._v(" " + _vm._s(_vm.item.supplier.trading_name) + " "),
                ]),
                _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                  _vm._v(" " + _vm._s(_vm.item.supplier.document) + " "),
                ]),
              ]),
              _c("div", { staticClass: "col-md-4 col-sm-4" }, [
                _c("h5", [_vm._v("Contatos")]),
                _vm.item.supplier.email
                  ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(" " + _vm._s(_vm.item.supplier.email) + " "),
                    ])
                  : _vm._e(),
                _vm.item.supplier.whatsapp
                  ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(" " + _vm._s(_vm.item.supplier.whatsapp) + " "),
                    ])
                  : _vm._e(),
                _vm.item.supplier.cellphone
                  ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(" " + _vm._s(_vm.item.supplier.cellphone) + " "),
                    ])
                  : _vm._e(),
                _vm.item.supplier.phone
                  ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(" " + _vm._s(_vm.item.supplier.phone) + " "),
                    ])
                  : _vm._e(),
                !_vm.item.supplier.email &&
                !_vm.item.supplier.whatsapp &&
                !_vm.item.supplier.cellphone &&
                !_vm.item.supplier.phone
                  ? _c("p", [
                      _vm._v(" Nenhum contato cadastrado até o momento. "),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "col-md-3 col-sm-3" }, [
                _c("h5", [_vm._v("Endereço")]),
                _vm.item.supplier.address
                  ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(" " + _vm._s(_vm.item.supplier.address) + " "),
                    ])
                  : _vm._e(),
                _vm.item.supplier.number
                  ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(
                        " Nº " +
                          _vm._s(_vm.item.supplier.number) +
                          " " +
                          _vm._s(_vm.item.supplier.complement) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.item.supplier.neighborhood
                  ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.supplier.neighborhood) +
                          ", " +
                          _vm._s(_vm.item.supplier.city) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm.item.supplier.zipcode
                  ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.item.supplier.state) +
                          " - " +
                          _vm._s(_vm.item.supplier.zipcode) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                !_vm.item.supplier.address &&
                !_vm.item.supplier.number &&
                !_vm.item.supplier.neighborhood &&
                !_vm.item.supplier.zipcode
                  ? _c("p", [
                      _vm._v(" Nenhum endereço cadastrado até o momento. "),
                    ])
                  : _vm._e(),
              ]),
              _vm.item.supplier.note
                ? _c("div", { staticClass: "col-md-12 col-sm-12" }, [
                    _c("p", { staticClass: "mt-2" }, [
                      _vm._v(
                        " Observação do: " + _vm._s(_vm.item.supplier.note)
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-6 col-sm-6" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    staticStyle: { "min-height": "180px" },
                  },
                  [
                    _c("h5", { staticClass: "mb-2" }, [
                      _c("strong", [_vm._v("DETALHES")]),
                    ]),
                    _vm.item.document_type
                      ? _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                          _c("strong", [_vm._v("Tipo doc.")]),
                          _vm._v(
                            " " + _vm._s(_vm.item.document_type.title) + " "
                          ),
                        ])
                      : _vm._e(),
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _c("strong", [_vm._v(" Categoria ")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.item.category
                              ? _vm.item.category.title
                              : "Nenhuma"
                          ) +
                          " "
                      ),
                    ]),
                    _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                      _c("strong", [_vm._v("Histórico PC")]),
                      _vm._v(" " + _vm._s(_vm.item.plan.title) + " "),
                    ]),
                    _c("p", { staticStyle: { "margin-bottom": "0px" } }, [
                      _c("strong", [_vm._v("Nº Documento")]),
                      _vm._v(" " + _vm._s(_vm.item.document_number) + " "),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6 col-sm-6" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    staticStyle: { "min-height": "180px" },
                  },
                  [
                    _c("h5", { staticClass: "mb-2" }, [
                      _c("strong", [_vm._v("CONTA BANCÁRIA")]),
                      _vm._v(" "),
                      _c("small", [_vm._v("- Fornecedor")]),
                    ]),
                    _vm.item.supplier.bank_account
                      ? _c("div", [
                          _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                            _c("strong", [_vm._v("Agência:")]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.item.supplier.bank_account.agency) +
                                " "
                            ),
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                            _c("strong", [_vm._v("Nº Conta:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.item.supplier.bank_account.account_number
                                ) +
                                " "
                            ),
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "5px" } }, [
                            _c("strong", [_vm._v("Banco:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.item.supplier.bank_account.bank
                                    ? _vm.item.supplier.bank_account.bank.title
                                    : ""
                                ) +
                                " "
                            ),
                          ]),
                          _c("p", { staticStyle: { "margin-bottom": "0px" } }, [
                            _c("strong", [_vm._v("Chave PIX:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.item.supplier.bank_account.key_pix
                                    ? _vm.item.supplier.bank_account.key_pix
                                    : "Não cadastrada"
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _c("p", [
                          _vm._v(
                            " Nenhuma conta bancária vinculada até o momento. "
                          ),
                        ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("b-card", { staticClass: "mb-1" }, [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col-md-9 col-sm-9" }, [
                _c("h5", { staticClass: "mb-1" }, [
                  _c("strong", [_vm._v("DESCRIÇÃO")]),
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.item.description
                        ? _vm.item.description
                        : "Nenhuma até o momento."
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "col-md-3 col-sm-3 text-center" }, [
                _c(
                  "strong",
                  {
                    staticClass: "d-block",
                    staticStyle: { "margin-bottom": "10px" },
                  },
                  [_vm._v("VALOR TOTAL DO PEDIDO")]
                ),
                _c("h4", { staticClass: "text-center" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm._f("toCurrency")(_vm.item.total))),
                  ]),
                ]),
                _c("small", { staticClass: "text-center d-block" }, [
                  _vm._v(
                    " (parcelado em " + _vm._s(_vm.item.installments) + "x) "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "form-row mt-1" }, [
            _c(
              "div",
              { staticClass: "col-md-7 col-sm-7" },
              [
                _c(
                  "b-card",
                  { staticClass: "mb-1" },
                  [
                    _c("h5", { staticClass: "mb-1" }, [
                      _c("strong", [_vm._v("RATEIO")]),
                    ]),
                    _vm._l(_vm.item.strategic_units, function (element, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "form-row align-items-center",
                        },
                        [
                          _c("div", { staticClass: "col-md-6 col-sm-6" }, [
                            _c("div", { staticClass: "form-group mb-0" }, [
                              _vm._v(
                                " " + _vm._s(element.strategic_unit.title) + " "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-4 col-sm-3" }, [
                            _c(
                              "div",
                              { staticClass: "form-group mb-0 text-center" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("toCurrency")(element.total)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-2 col-sm-2 text-center" },
                            [
                              _c("div", { staticClass: "form-group mb-0" }, [
                                _vm._v(" " + _vm._s(element.percent) + "% "),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-12 col-sm-12" },
                            _vm._l(element.business, function (el, i) {
                              return _c(
                                "div",
                                {
                                  key: "business-strategic-key-" + i,
                                  staticClass:
                                    "form-row align-items-center mt-1",
                                  class: {
                                    "mb-1": i !== element.business.length - 1,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-md-7 col-sm-8" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mb-0" },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "bi bi-arrow-return-right",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(el.business_unit.title) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-md-2 col-sm-2 text-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mb-0" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(el.percent) + "% "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-md-3 col-sm-2 text-right",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "form-group mb-0" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("toCurrency")(el.total)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          index !== _vm.item.strategic_units.length - 1
                            ? _c("div", { staticClass: "col-12" }, [_c("hr")])
                            : _vm._e(),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-5 col-sm-5" },
              [
                _c("b-card", { staticClass: "mb-1" }, [
                  _c("h5", { staticClass: "mb-1" }, [
                    _c("strong", [_vm._v("PARCELAS")]),
                  ]),
                  _vm.item.all_installments && _vm.item.all_installments.length
                    ? _c(
                        "ul",
                        {
                          staticClass: "list-group list-group-flush",
                          staticStyle: { "font-size": "0.9rem" },
                        },
                        _vm._l(
                          _vm.item.all_installments,
                          function (item, index) {
                            return _c(
                              "li",
                              {
                                key: "li-installment-" + index,
                                staticClass: "list-group-item px-0 px-1",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Status: " + item.status,
                                        expression: "`Status: ${item.status}`",
                                        modifiers: { hover: true, top: true },
                                      },
                                    ],
                                    staticClass: "d-flex",
                                    staticStyle: {
                                      "justify-content": "space-between",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("i", { class: "bi " + item.icon }),
                                      _vm._v(
                                        " " +
                                          _vm._s(item.installment) +
                                          " - " +
                                          _vm._s(
                                            _vm._f("toCurrency")(item.total)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm._f("datePT")(item.date)) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _c("p", [
                        _vm._v(
                          " O usuário não cadastrou nenhum pagamento/parcela. "
                        ),
                      ]),
                ]),
              ],
              1
            ),
            _vm.item.files.length
              ? _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-card",
                      [
                        _c("h5", { staticClass: "mb-1" }, [
                          _c("strong", [_vm._v("ARQUIVOS ANEXADOS")]),
                        ]),
                        _vm._l(_vm.item.files, function (file, index) {
                          return _c(
                            "div",
                            {
                              staticClass: "form-row align-items-center mt-1",
                              staticStyle: {
                                "align-items": "center",
                                "font-size": "0.9rem",
                              },
                            },
                            [
                              _c("div", { staticClass: "col-md-9 text-left" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href: file.url,
                                      title: "Acessar arquivo",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-cloud-download",
                                    }),
                                    _vm._v(
                                      " Arquivo " + _vm._s(index + 1) + " "
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-2 text-left" }, [
                                _vm._v(" " + _vm._s(file.extension) + " "),
                              ]),
                              _c(
                                "div",
                                { staticClass: "col-md-1 text-center" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("bytesToMB")(file.size, 2)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }