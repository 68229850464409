<template>
  <div class="container" style="max-width: 900px; border-radius: 6px">
    <div class="form-row no-print justify-content-center">
      <div class="col-md-2">
        <router-link :to="`${$previousRoute}`" class="btn-block btn btn-light mt-1 no-print" style="display: block; margin: auto">
          VOLTAR
        </router-link>
      </div>
      <div class="col-md-2">
        <button @click="printPage" type="button" class="btn-block btn btn-primary mt-1 no-print" style="display: block; margin: auto">
          IMPRIMIR
        </button>
      </div>
    </div>

    <div id="printContainer" ref="printContainer"></div>
    
    <div class="d-block mt-1" ref="imprimir">
      <b-card class="mb-1">
        <h4 class="text-center mb-0">
          <div class="form-row" style="align-content: center;font-size: 1.1rem;justify-content: space-between; align-items: center;">
            <div class="col-md-3 col-sm-3 text-left">
              <strong>Nº {{ item.id }}</strong>
            </div>
            <div class="col-md-6 text-center col-sm-6">
              <strong class="title-page">PEDIDO DE COMPRA E LIBERAÇÃO DE PAGAMENTOS </strong>
            </div>
            <div class="col-md-3 col-sm-3">
              <strong>{{ item.created_at | datePT(true) }}</strong>
            </div>
          </div>
        </h4>
      </b-card>

      <b-card class="mb-1">
        <div class="form-row">
          <div class="col-md-12 col-sm-12">
            <h5 class="mb-1"><strong>FORNECEDOR</strong></h5>
          </div>

          <div class="col-md-5 col-sm-5">
            <p style="margin-bottom: 5px;">
              {{ item.supplier.title }}
            </p>
            <p style="margin-bottom: 5px;">
              {{ item.supplier.trading_name }}
            </p>
            <p style="margin-bottom: 5px;">
              {{ item.supplier.document }}
            </p>
          </div>

          <div class="col-md-4 col-sm-4">
            <h5>Contatos</h5>
            <p v-if="item.supplier.email" style="margin-bottom: 5px;">
              {{ item.supplier.email }}
            </p>
            <p v-if="item.supplier.whatsapp" style="margin-bottom: 5px;">
              {{ item.supplier.whatsapp }}
            </p>
            <p v-if="item.supplier.cellphone" style="margin-bottom: 5px;">
              {{ item.supplier.cellphone }}
            </p>
            <p v-if="item.supplier.phone" style="margin-bottom: 5px;">
              {{ item.supplier.phone }}
            </p>

            <p v-if="!item.supplier.email && !item.supplier.whatsapp && !item.supplier.cellphone && !item.supplier.phone">
              Nenhum contato cadastrado até o momento.
            </p>
          </div>

          <div class="col-md-3 col-sm-3">
            <h5>Endereço</h5>
            <p v-if="item.supplier.address" style="margin-bottom: 5px;">
              {{ item.supplier.address }}
            </p>
            <p v-if="item.supplier.number" style="margin-bottom: 5px;">
              Nº {{ item.supplier.number }} {{ item.supplier.complement }}
            </p>
            <p v-if="item.supplier.neighborhood" style="margin-bottom: 5px;">
              {{ item.supplier.neighborhood }}, {{ item.supplier.city }}
            </p>
            <p v-if="item.supplier.zipcode" style="margin-bottom: 5px;">
              {{ item.supplier.state }} - {{ item.supplier.zipcode }}
            </p>

            <p v-if="!item.supplier.address && !item.supplier.number && !item.supplier.neighborhood && !item.supplier.zipcode">
              Nenhum endereço cadastrado até o momento.
            </p>
          </div>

          <div v-if="item.supplier.note" class="col-md-12 col-sm-12">
            <p class="mt-2">
              Observação do:
              {{ item.supplier.note }}</p>
          </div>
        </div>
      </b-card>

      <div class="form-row">
        <div class="col-md-6 col-sm-6">
          <b-card class="mb-1" style="min-height: 180px;">
            <h5 class="mb-2"><strong>DETALHES</strong></h5>
            <p v-if="item.document_type" style="margin-bottom: 5px;">
              <strong>Tipo doc.</strong>
              {{ item.document_type.title }}
            </p>
            <p style="margin-bottom: 5px;">
              <strong> Categoria </strong>
              {{ item.category ? item.category.title : 'Nenhuma' }}
            </p>
            <p style="margin-bottom: 5px;">
              <strong>Histórico PC</strong>
              {{ item.plan.title }}
            </p>
            <p style="margin-bottom: 0px;">
              <strong>Nº Documento</strong>
              {{ item.document_number }}
            </p>
          </b-card>
        </div>
        
        <div class="col-md-6 col-sm-6">
          <b-card class="mb-1" style="min-height: 180px;">
            <h5 class="mb-2"><strong>CONTA BANCÁRIA</strong> <small>- Fornecedor</small></h5>
            <div v-if="item.supplier.bank_account">
              <p style="margin-bottom: 5px;">
                <strong>Agência:</strong> {{ item.supplier.bank_account.agency }}
              </p>
              <p style="margin-bottom: 5px;">
                <strong>Nº Conta:</strong> {{ item.supplier.bank_account.account_number }}
              </p>
              <p style="margin-bottom: 5px;">
                <strong>Banco:</strong> {{ item.supplier.bank_account.bank ? item.supplier.bank_account.bank.title : '' }}
              </p>
              <p style="margin-bottom: 0px;">
                <strong>Chave PIX:</strong> {{ item.supplier.bank_account.key_pix ? item.supplier.bank_account.key_pix : "Não cadastrada" }}
              </p>
            </div>
            <p v-else>
              Nenhuma conta bancária vinculada até o momento.
            </p>
          </b-card>
        </div>
      </div>

      <b-card class="mb-1">
        <div class="form-row">
          <div class="col-md-9 col-sm-9">
            <h5 class="mb-1"><strong>DESCRIÇÃO</strong></h5>
            {{ item.description ? item.description : 'Nenhuma até o momento.' }}
          </div>

          <div class="col-md-3 col-sm-3 text-center">
            <strong class="d-block" style="margin-bottom: 10px;">VALOR TOTAL DO PEDIDO</strong>
            <h4 class="text-center">
              <strong>{{  item.total | toCurrency }}</strong>
            </h4>
            <small class="text-center d-block">
              (parcelado em {{ item.installments }}x)
            </small>
          </div>
        </div>    
      </b-card>
      
      <div class="form-row mt-1">
        <div class="col-md-7 col-sm-7">
          <b-card class="mb-1">
            <h5 class="mb-1"><strong>RATEIO</strong></h5>
            <div
            v-for="(element, index) in item.strategic_units"
            :key="index"
            class="form-row align-items-center"
            >
              <div class="col-md-6 col-sm-6">
                <div class="form-group mb-0">
                  {{ element.strategic_unit.title }}
                </div>
              </div>

              <div class="col-md-4 col-sm-3">
                <div class="form-group mb-0 text-center">
                  {{ element.total | toCurrency }}
                </div>
              </div>

              <div class="col-md-2 col-sm-2 text-center">
                <div class="form-group mb-0">
                  {{ element.percent }}%
                </div>
              </div>

              <div class="col-md-12 col-sm-12">
                <div class="form-row align-items-center mt-1" :class="{ 'mb-1': i !== element.business.length - 1 }"
                  v-for="(el, i) in element.business" :key="`business-strategic-key-${i}`">
                  <div class="col-md-7 col-sm-8">
                    <div class="form-group mb-0">
                      <i class="bi bi-arrow-return-right"></i> {{ el.business_unit.title }}
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-2 text-center">
                    <div class="form-group mb-0">
                      {{ el.percent }}%
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-2 text-right">
                    <div class="form-group mb-0">
                      {{ el.total | toCurrency }}
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="index !== item.strategic_units.length - 1" class="col-12">
                <hr>
              </div>
            </div>
          </b-card>
        </div>

        <div class="col-md-5 col-sm-5">
          <b-card class="mb-1">
            <h5 class="mb-1"><strong>PARCELAS</strong></h5>
            <ul v-if="item.all_installments && item.all_installments.length" class="list-group list-group-flush"
              style="font-size: 0.9rem">
              <li class="list-group-item px-0 px-1" v-for="(item, index) in item.all_installments"
                :key="`li-installment-${index}`">
                <div v-b-tooltip.hover.top="`Status: ${item.status}`" class="d-flex"
                  style="justify-content: space-between; align-items: center;">
                  <div>
                    <i :class="`bi ${item.icon}`"></i>
                    {{ item.installment }} - {{ item.total | toCurrency }}
                  </div>
                  <div>
                    {{ item.date | datePT }}
                  </div>
                </div>
              </li>
            </ul>
            <p v-else>
              O usuário não cadastrou nenhum pagamento/parcela.
            </p>
            <!-- <hr>
            <h4 class="mt-2">Arquivos</h4>
            <div class="form-row align-items-center mt-1" v-if="item.files.length"
            style="align-items: center;font-size: 0.9rem;" v-for="(file, index) in item.files">
            <div class="col-md-5 text-left">
              <a target="_blank" :href="file.url" title="Acessar arquivo">
                <i class="bi bi-cloud-download"></i> Arquivo {{ index + 1 }}
              </a>
            </div>
            <div class="col-md-3 text-left">
              {{ file.extension }}
            </div>
            <div class="col-md-4 text-center">
              {{ file.size | bytesToMB(2) }}
            </div>
            </div> -->
          </b-card>
        </div>

        <div class="col-md-12" v-if="item.files.length">
          <b-card>
            <h5 class="mb-1"><strong>ARQUIVOS ANEXADOS</strong></h5>
              <div class="form-row align-items-center mt-1"
              style="align-items: center;font-size: 0.9rem;" v-for="(file, index) in item.files">
              <div class="col-md-9 text-left">
                <a target="_blank" :href="file.url" title="Acessar arquivo">
                  <i class="bi bi-cloud-download"></i> Arquivo {{ index + 1 }}
                </a>
              </div>
              <div class="col-md-2 text-left">
                {{ file.extension }}
              </div>
              <div class="col-md-1 text-center">
                {{ file.size | bytesToMB(2) }}
              </div>
              </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  VBTooltip,
  BCard,
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
  },
  computed: {
    previousRoute() {
      return this.$previousRoute || '/orders/purchases/list';
    },
  },
  data() {
    return {
      item: {
        tags: [],
        supplier: {
          bank_account: {
            pix_key: '',
            agency: '',
            account_number: '',
            bank: ''
          },
        },
        issue_date: "",
        total: "",
        due_date: "",
        category: "",
        document_number: "",
        occurrence: "",
        note: "",
        document_type: "",
        plan: "",
        description: "",
        strategic_units: [],
        installments: "",
        installment_first: "",
        installment_due: "",
        status: "",
        step: "",
        user: "",
        files: [],
      },
    }
  },
  methods: {
    async getData() {
      this.$store.dispatch("OrderPurchase/edit", this.$route.params.uuid).then((res) => {
        this.item = res
      })
    },
    printPage() {
     // Pega o conteúdo do elemento "imprimir"
     const content = this.$refs.imprimir.innerHTML;
      // Pega a referência do contêiner de impressão
      const printContainer = this.$refs.printContainer;

      // Define o conteúdo do contêiner de impressão
      printContainer.innerHTML = content;

      setTimeout(() => {
        window.print();
      }, 1000);      
      // Após a impressão, limpar o conteúdo do contêiner de impressão
      printContainer.innerHTML = '';
    }
  },
  async mounted() {
    this.getData()
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
}
</script>

<style >
@media print {
  body {
    background-color: #f8f8f8;
  }
  .title-page {
    font-size: 1rem;
  }
  .card {
    background-color: #fff;
    border: 1px solid #000;
  }

  .no-print {
    display: none !important;
  }

  #printContainer {
    display: block;
  }
}

#printContainer {
  display: none;
}
</style>